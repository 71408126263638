<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center" align="center">
            <v-col cols="6">
                <v-card class="ml-2">
                    <v-form ref="form" v-model="isValid">
                      <div v-if="!hasData" style="text-align: center;">
                          <span class="text-sm-h4 font-weight-bold">請輸入基本資料</span><br>
                          <v-text-field label="身份證字號" outlined v-model="userData.idno" class="mx-5 mb-n3" :rules="[rules.required]" required></v-text-field>
                          <v-text-field label="姓名" outlined v-model="userData.name" class="mx-5 mb-n3" :rules="[rules.required]" required></v-text-field>
                          <v-text-field label="電話" outlined v-model="userData.phone" class="mx-5 mb-n3" :rules="[rules.required, rules.phone]" required></v-text-field>
                          <v-btn class="mr-4 text-sm-h6 white--text mb-5" min-width="250" color="#37906C" large @click="submitData" :disabled="!isValid">產生個人QRcode</v-btn>
                      </div>
                      <div v-if="hasData"  style="text-align: center;">
                        <span class="text-sm-h5 font-weight-bold">您的個人QRcode，可截圖使用，無須再次輸入基本資料，即可掃瞄入場。</span><br>
                        <vue-qrcode :value="qrData"  width="300" />
                      </div>
                    </v-form>
                </v-card>                            
            </v-col>
        </v-row>
    <br/>
    </v-container>
  </v-app>
</template>


<script>
import commonMixin from '@/utils/commonMixin';
import VueQrcode from 'vue-qrcode';

export default {
  name: 'personalQR',
  mixins: [commonMixin],
  created(){
    
  },
  destroyed() {
    
  },
  mounted(){
    
  },
  methods: {
    submitData: function() {
      if (this.checkNoId(this.userData.idno)) {
        this.qrData = JSON.stringify(this.userData);
        this.hasData = true;
      } else {
        this.$swal("身分證字號格式錯誤");
      }
    },

    checkNoId: function(userIdNo) {
      if (typeof userIdNo == "undefined")
        userIdNo = "";

      if (userIdNo.length < 10) {
        return false || (userIdNo.length + " / 10");
      }

      userIdNo = userIdNo.toUpperCase();
      // 依照字母的編號排列。
      var idHeader = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
      // 儲存各個乘數
      var multiply = new Array(1, 9, 8, 7, 6, 5, 4, 3, 2, 1);
      var nums = new Array(3);
      var firstChar;
      var firstNum;
      var lastNum;
      var total = 0;

      // 撰寫「正規表達式」。第一個字為英文字母，
      var regExpID0=/^[a-z](1|2|8|9)\d{8}$/i; //台灣人第二個字為1或2，新居留證號第二個字為8或9，後面跟著8個數字，不分大小寫。
      var regExpID1=/^[a-z]([a-d])\d{8}$/i; //外國人第二個字為ABCD，後面跟著7個數字，不分大小寫。
      // 使用「正規表達式」檢驗格式
      if (userIdNo.search(regExpID0)!=-1) {
        nums[2] = parseInt(userIdNo.charAt(1));
        //return false || "本國人";
      } else if (userIdNo.search(regExpID1)!=-1) {
        nums[2] = idHeader.indexOf(userIdNo.charAt(1)) % 10;
        //console.log(nums[2]);
        //return false || "外國人";
      } else {
        return false;
      }
      firstChar = userIdNo.charAt(0).toUpperCase();
      lastNum = userIdNo.charAt(9);

      // 找出第一個字母對應的數字，並轉換成兩位數數字。
      firstNum = idHeader.indexOf(firstChar) + 10;
      nums[0] = Math.floor(firstNum / 10);
      nums[1] = firstNum - (nums[0] * 10);

      // 執行加總計算
      for(var i=0; i<multiply.length; i++){
        if (i<3) {
          total += nums[i] * multiply[i];
        } else {
          total += parseInt(userIdNo.charAt(i-1)) * multiply[i];
        }
      }

     total =  total + parseInt(lastNum);
      // 和最後一個數字比對
      if ((total % 10) != 0) {
        return false;
      } 
      return true;
    },
  },
  watch: {
    
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      phone: value => {
        const pattern = /^[0-9]{8,10}$/
        return pattern.test(value) || '輸入完整電話號碼'
      },
    },
    hasData: false,
    userData: {
        idno: "",
        name: "",
        phone: "",
    },
    qrData: "",
    isValid: true,
  }),
  components: {
    VueQrcode,
  },
}
</script>